import 'bootstrap5';

// keep these scss files as separate modules for faster builds (import here instead in the global scss)
import './styles/bootstrap.scss';
import './styles/app.scss';

import './js/forms/form';
import './js/forms/registered-data';
import './js/app';
import './js/workflow-form';
import './js/application';
import './js/main';
import './js/vote';
import './js/winners';
import './js/layout/navigation';

window.addEventListener('scroll', function() {
  const image = document.querySelector('.herb');
  if (!image) {
    return;
  }
  const imageRect = image.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const distanceFromTop = imageRect.top;
  let brightness = 1 - Math.min(1, Math.max(0, distanceFromTop / windowHeight));
  if (brightness < 0.5) {
    brightness = brightness/2;
  }
  image.style.filter = 'brightness(' + brightness + ')';
});
